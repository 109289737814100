import React, { useLayoutEffect } from 'react'
import { RenderMarkdown } from 'organisms'

const content = `
Last modified: 06/10/2023

BharatRohan Airborne Innovations Limited (“**BharatRohan**”/“**we**”/“**us**”) understands the sensitivity of the personal or financial information shared or to be shared by you, the user or visitor of BharatRohan’s Website, and is committed to protecting your privacy through our compliance with this privacy policy.

This policy describes the types of information we may collect from you or that you may provide when you visit BharatRohan’s website at [BharatRohan.in](https://bharatrohan.in/) (our “**Website**”) and our practices for collecting, using, maintaining, protecting, and disclosing that information. This policy is also subject to the Terms of Use available at [Terms Of Use](/terms-of-use).

This policy applies to information we collect:
1)	On this Website.
2)	In e-mail, text, and other electronic messages between you and BharatRohan or this Website.

It does not apply to information collected by:
1) Any third party, including through any application or content (including advertising) that may link to or be accessible from or on the Website. 

##### Consent To This Policy
Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. By using the Website or by otherwise giving us your information, you will be deemed to have read, understood and agreed to this privacy policy and agree to be bound by the privacy policy. You hereby consent to our collection, use and sharing, disclosure of your Personal Information, Usage Information and all other information as described in this privacy policy. If you do not agree with our policies and practices, you must not access the Website or purchase or use any products or services available on the Website. If you use the Website on behalf of someone else (such as a child) or an entity (such as your employer), you represent that you are authorized by such individual or entity to (i) accept this privacy policy on such individual’s or entity’s behalf, and (ii) consent on behalf of such individual or entity to our collection, use and disclosure of such individual’s or entity’s information as described in this privacy policy. We reserve the right to change, modify, add or delete portions of this privacy policy, at our sole discretion, at any time. Your continued use of the Website after the changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.

##### Information We Collect About You and How We Collect It
We may collect various information from and about users of the Website, including information:
1) By which you may be personally identified, such as name, postal address, e-mail address, telephone number, login address / password, financial or banking information, or any other identifier by which you may be contacted online or offline (“**Personal information**”)
2) That is about you but individually does not identify you, including but not limited to geo-location information when permitted by you on your device, and any other information provided by you on our Website; and/or about your searches on our Website, call data records, services checked or verified on our Website, internet connection, the equipment you use to access our Website and usage details (“**Usage Information**”).

We collect this information:
1) Directly from you when you provide it to us.
2) Automatically as you navigate through the Website. Information collected automatically may include usage details, IP addresses, and information collected through cookies, and other tracking technologies.
3) From third parties, for example, our business partners, who have received the information from you with consent to further disclose to third parties.

##### Information You Provide to Us
The information we collect on or through our Website may include:
1) Personal Information, Usage Information and other information that you provide by filling in forms on our Website. This includes information provided at the time of registering to use our Website, subscribing to any service, or requesting further services. We may also ask you for information when you report a problem with our Website.
2) Records and copies of your correspondence (including e-mail addresses), if you contact us.
3) Your responses to surveys that we might ask you to complete.
4) Details of transactions you carry out through our Website and of the fulfillment of your orders, which includes your financial information before placing an order through our Website.
5) Other information as described in this Privacy Policy.

You also may provide information to be published or displayed (hereinafter, “**posted**”) on public areas of the Website, or transmitted to other users of the Website or third parties (collectively, “**User Contributions**”). Your User Contributions are posted on and transmitted to others at your own risk. Also, please be aware that no security measures are perfect or impenetrable. Additionally, we cannot control the actions of other users of the Website with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized persons.

##### Information We Collect Through Automatic Data Collection Technologies
As you navigate through and interact with our Website, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:
1) Details of your visits to our Website, including traffic data, location data, logs, and other communication data and the resources that you access and use on the Website.
2) Information about your computer and internet connection, including your IP address, operating system, and browser type.

We also may use these technologies to collect information about your online activities over time and across third-party websites or other online services (behavioral tracking). The information we collect automatically helps us to improve our Website and to deliver a better and more personalized service, including by enabling us to:
1) Estimate our audience size and usage patterns.
2) Store information about your preferences, allowing us to customize our Website according to your individual interests.
3) Speed up your searches.
4) Recognize you when you return to our Website.

We may use available technology for such automatic data collection, including but not limited to:
1) **Cookies (or browser cookies)**: A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our Website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Website.
2) **Flash Cookies**: Certain features of our Website may use local stored objects (or flash cookies) to collect and store information about your preferences and navigation to, from, and on our Website. Flash cookies are not managed by the same browser settings as are used for browser cookies and we advise you to keep yourself updated on how to manage your privacy and security settings for flash cookies on your device.
3) **Web Beacons**: Pages of our Website and our e-mails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit BharatRohan, for example, to count users who have visited those pages or opened an e-mail and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).

We may not collect Personal Information or Usage Information automatically, but we may tie this information to Personal Information or Usage Information about you that you provide to us or that we collect from other sources.

##### Third-Party Use of Cookies and Other Tracking Technologies
Some content or applications, including any advertisements, on the Website may be served by third parties, including payment gateway service providers, advertisers, ad networks and servers, content providers, and application providers. These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you when you use our Website. The information they collect may be associated with your Personal Information or Usage Information or they may collect information, including Personal Information, about your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content. 
We do not control these third parties’ tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly. 

##### How We Use Your Information
We use information that we collect about you or that you provide to us, including any Personal Information or Usage Information:
1) To present our Website and its contents to you.
2) To provide you with information, products, or services that we or other third-party service providers may offer on our Website.  
3) To fulfill any other purpose for which you provide it.
4) To provide you with notices about your account, subscription and orders, including payment information, cancellations, or other notices.
5) To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.
6) To notify you about changes to our Website or any products or services that we or other third-party service providers offer or provide through it.
7) To allow you to participate in interactive features by way of User Contributions on our Website.
8) In any other way we may describe when you provide the information.
9) For any other purpose with your express consent.

We may also use your information to contact you about our own products and services that may be of interest to you. If you do not want us to use your information in this way, please inform us accordingly.
We may use the information we have collected from you to enable us to display advertisements to our advertisers’ target audiences. Even though we do not disclose your personal information for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria.

##### Disclosure of Your Information
We may disclose aggregated information about our users, and information that does not identify any individual, without restriction. We may disclose Personal Information or Usage Information that we collect or you provide as described in this privacy policy:
1) To service providers and other third parties we use to support our business, provide products or services to you and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.
2) To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of BharatRohan’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by BharatRohan about our Website users is among the assets transferred.
3) To third parties to market their products or services to you if you have consented to these disclosures. We contractually require these third parties to keep personal information confidential and use it only for the purposes for which we disclose it to them and do not share your financial information to such third parties for marketing purposes. 
4) To fulfill the purpose for which you provide it or for any other purpose disclosed by us when you provide the information or with your consent.

We may also disclose your personal information:
1) To comply with any court order, law, or legal process, including any government request.
2) To enforce or apply our Terms of Use and other agreements, including for billing and collection purposes.
3) If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of BharatRohan and its partners. 

##### Choices About How We Use and Disclose Your Information
We strive to provide you with choices regarding your Personal Information or Usage Information. We have created mechanisms to provide you with the following control over your information:
1) Tracking Technologies and Advertising. You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies, please note that some parts of the Website may then be inaccessible or not function properly.
2) Promotional Offers from BharatRohan. If you do not wish to have your e-mail address/contact information used by BharatRohan to promote our own or third parties’ products or services, you can opt out by e-mailing your requirement to us at [info@bharatrohan.in](mailto:info@bharatrohan.in). 
3) We do not control third parties’ collection or use of your information to serve interest-based advertising. However, these third parties may provide you with ways to choose not to have your information collected or used in this way. 
4) Data Protection Officer. In the event you are not satisfied with or do not consent to the manner of use of your Personal Information or Usage Information, you may state your request in writing to [info@bharatrohan.in](mailto:info@bharatrohan.in), who is appointed as our Data Protection Officer (DPO).

##### Accessing and Correcting Your Information
You can review and change your personal information by logging into the Website and visiting your account profile page.
You may also send us an e-mail to correct or delete any personal information that you have provided to us. Please note that we may not be able to delete your Personal Information or Usage Information except by also deleting your user account. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.
If you delete your User Contributions from the Website, copies of your User Contributions may remain viewable in cached and archived pages, or might have been copied or stored by other Website users. 
In addition to your rights stated in this Privacy Policy or the Terms of Use, you also have the following basic rights regarding your Personal Information or Usage Information: 

You have the right to:
- Obtaining a copy of your personal data and receiving information about the processing of your personal data.
- Ask for incorrect, inaccurate or incomplete personal data to be corrected.
- Request that personal data be erased when it is no longer needed or if processing it is unlawful.
- Object to the processing of your personal data for marketing purposes or on grounds relating to your particular situation.
- Request the restriction of the processing of your personal data in specific cases.
- Receive your personal data in a machine-readable format and send it to another controller identified by you (‘data portability’).
- Request that decisions based on automated processing concerning you or significantly affecting you and based on your personal data are made by natural persons, not only by computers. You also have the right in this case to express your point of view and to contest the decision.

To exercise the above rights, you should contact and address your request to the DPO. BharatRohan shall respond to your request within one (1) month from the date of your request. You may be asked to provide information to confirm your identity (such as, clicking a verification link, entering a username or password) in order to exercise your rights.

##### Data Security
We have implemented commercially reasonable measures designed to secure your information from accidental loss and from unauthorized access, use, alteration, and disclosure. Payment transactions, if any, will be encrypted and processed through secure sites of approved payment gateways. 

Safety and security of your information also depends on you. Where we have given you or where you have chosen a password for access to certain parts of our Website, you are responsible for keeping it confidential. We ask you not to share your password with anyone. Further, you have an option of not saving your banking or financial information, including details of your debit or credit card, on the Website, so that you may reduce the risk of such information being accessed by any third party. 

Unfortunately, the transmission of information via the internet is not completely secure. Although we take commercially reasonable measures to protect your personal information, we cannot guarantee the security of your information transmitted to our Website. Any transmission of Personal or Usage Information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website.

It is important for you to protect against unauthorized access to your password and your electronic devices. You are responsible for logging off from the Website after use. BharatRohan does not undertake any liability for any unauthorized use of your account and password. If you suspect any unauthorized use of your account, you must immediately notify us by sending an e-mail. 

Further, part of the functionality of the Website involves using service providers to create and maintain the Website, as well as execute the orders placed by the users. This may require BharatRohan to disclose information pertaining to the user and their orders to other service providers. However, BharatRohan is not responsible for the confidentiality, security or distribution of any Personal Information, Usage Information or other information by such third parties. BharatRohan shall also not be responsible for any breach of security or for any actions of any third parties or events that are beyond the reasonable control of BharatRohan, including but not limited to, acts of government, computer hacking, unauthorized access to computer data and storage device, computer crashes, breach of security and encryption, etc.

##### Changes to Our Privacy Policy
It is our policy to post any changes we make to our privacy policy on this page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for periodically visiting our Website and this privacy policy to check for any changes.

##### Use by Minors
The Website is not meant to be used by or intended for minors. BharatRohan strongly advises the parents or guardians to supervise the use of the Website by their minor children. Any use of the Website and provision of Personal Information, Usage Information or other information to us by any minor is deemed to have been provided under due supervision and consent by such parents or guardians. 

##### Account Closure and Contact Information
If you wish to cancel your account or request that we no longer use your information to provide you services, please let us know using the contact details below. We may retain your information for as long as your account with us is active and as needed to provide you the services to fulfil any order placed by you. We shall not retain such information for longer than is required for the purposes for which the information may lawfully be used or is otherwise required under law. After the account closure, your data may be anonymized and aggregated, and then may be held by us as long as necessary for us to provide our services effectively, but our use of the anonymized data will be solely for analytic purposes. Please note that your withdrawal of consent, or cancellation of account may result in BharatRohan being unable to provide you with its services or to terminate any existing relationship BharatRohan may have with you. To ask questions or comment about this privacy policy, contact the DPO or write directly to us at:

[info@bharatrohan.in](mailto:info@bharatrohan.in)  
or write to us at:  
BharatRohan Airborne Innovations Limited (Formerly known as BharatRohan Airborne Innovations Private Limited), 301, Tower 4, DLF Corporate Greens, Sector 74a Gurgaon , Haryana 122004, IN.

##### Governing Law and Dispute Resolution
All matters relating to the Website and this Privacy Policy and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall exclusively be governed by and construed in accordance with the laws of India.
Any dispute, controversy, claims or disagreement of any kind whatsoever arising out of or in connection with this Privacy Policy (or the breach, termination or invalidity thereof) or the Website or any products or services sold using or through the Website (hereinafter referred to as a “Dispute”) shall be exclusively referred to and finally resolved by arbitration in accordance with the provisions of the Arbitration and Conciliation Act, 1996. The arbitration shall be held in Bengaluru, India and the Arbitral Tribunal shall consist of a sole arbitrator appointed by the Karnataka Arbitration Centre, Bengaluru. You/Purchaser waive/s any and all objections to the exercise of jurisdiction over you by such an arbitrator. The arbitrator’s decision shall be reasoned, in English, final and binding on you/purchaser and BharatRohan. Subject to the above, the courts in Bengaluru, India shall have exclusive jurisdiction over such Disputes.
`

export default function PrivacyPolicy() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="py-16 md:blade-top-padding-lg md:blade-bottom-padding-lg mb-4 bg-green">
        <h1 className="font-extrabold text-center text-white  blade-top-padding-lg blade-bottom-padding-sm leading-relaxed md:block hidden">
          Privacy policy
        </h1>
        <h3 className="font-bold text-center  px-5 text-white sm:w-10/12 lg:w-full mx-auto blade-top-padding-lg blade-bottom-padding-sm leading-relaxed md:hidden block">
          Privacy policy
        </h3>
      </div>
      <div className="markdown-wrapper md:max-w-xl lg:max-w-2xl xl:max-w-3xl 2xl:max-w-5xl sm:px-5 px-3 mx-auto blade-bottom-padding-lg blade-bottom-margin-lg">
        <RenderMarkdown content={content} />
      </div>
    </>
  )
}
