import { useState } from "react"

const data = [
    {
      title: 'CSR Policy',
      // month : months[0],
      fileUrl: './assets/policies/CSR_Policy.pdf',
    },
    // {
    //   title: 'Standalone Financials FY23-24',
    //   // month : months[0],
    //   files: './assets/finances/FS_FY23-24.pdf',
    // },
]

export default function Policy01() {
    const [selected, setSelected] = useState<number>(0)
    return (
        <div className="py-8 lg:pb-10"> {/* Replaceable line is down */}
      {/* <div className="py-8 bg-cream lg:pb-10"></div> */}
      {/* <div className="w-container-lg"> */}
        <div className="flex flex-col lg:flex-row items-start gap-x-12 xl:gap-x-16 2xl:gap-x-20 gap-y-6 md:gap-y-12">
          <div className="grid  w-full blade-top-padding-sm lg:pt-0 lg:w-[100%] gap-y-4 lg:gap-y-6 flex-col">
            {data.map((item, index) => (
              <div className='flex justify-around sm:flex'>
                <div className="md:w-[70%] sm:w-2" key={index}>
                  <div
                    onClick={() => {
                      setSelected(index)
                    }}
                    className={`flex w-full py-4 border px-4 sm:px-6 md:px-8 lg:px-4 xl:px-6 border-green items-center justify-between cursor-pointer transition-al duration-1000 ${
                      selected === index
                        ? 'bg-transparent cursor-auto pt-6'
                        : 'bg-transparent'
                    }`}
                  >
                    <a href={item.fileUrl} target='_blank' className="grid w-full grid-cols-[2.5rem_1fr_auto] sm:grid-cols-[3rem_1fr_auto] xl:grid-cols-[4rem_1fr_auto]">
                    <span
                      className={`text-lg lg:text-xl 2xl:text-xl opacity-25 font-bold transition-all duration-1000 ${
                        selected === index ? 'text-black' : 'text-black'
                      }`}
                    >{`0${index + 1}`}</span>
                      <h4
                        className={`${
                          selected === index ? 'text-green' : 'text-green'
                        } font-semibold text-left transition-all duration-1000 self-center 2xl:text-xl`}
                      >
                        {item.title}
                      </h4>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      {/* </div> */}
    </div>
    )
}