import React, { useEffect, useState } from 'react'

import AmanDeep from './panels/boardPanels/amanDeep'
import Rishabh from './panels/boardPanels/rishabh'
import Mukesh from './panels/boardPanels/mukesh'

import Meetings01 from './panels/meetingsPanels/meetings01'
import Meetings02 from './panels/meetingsPanels/meetings02'
import Meetings03 from './panels/meetingsPanels/meetings03'

import GroupCompanies01 from './panels/groupCompanies/groupCompanies01'
import GroupCompanies02 from './panels/groupCompanies/groupCompanies02'
import GroupCompanies03 from './panels/groupCompanies/groupCompanies03'

import Financial01 from './panels/financialPanels/financialPanels01';
import Financial02 from './panels/financialPanels/financialPanels02';
import Financial03 from './panels/financialPanels/financialPanels03';
import Policy01 from './panels/policies/policyPanels01'

// import Financial02 from './panels/FinancialPanels/financial02'
// import Financial03 from './panels/FinancialPanels/financial03'

const Sidebar: React.FC = () => {
  const [openTab, setOpenTab] = useState<string | null>('DetailsOfBoard')
  const [openPanel, setOpenPanel] = useState<string | null>('DetailsOfBoard')

  const handleTabClick = (tabName: string) => {
    if (tabName === openTab) {
      return
    } else {
      setOpenTab(openTab === tabName ? null : tabName)
      setOpenPanel(tabName)
    }
  }

  const handlePanelClick = (panelName: string) => {
    setOpenPanel(openPanel === panelName ? panelName : panelName)
  }
  useEffect(() => {
    console.log(openPanel)
  }, [])

  return (
    <div className="flex md:flex-row flex-col relative min-h-[25rem] mb-10 gap-10">
      {/* All the tabs and nested tabs are rendered here */}
      <div className="flex-1 2xl:max-w-md max-w-sm w-full h-full bg-green-800 pt-6">
        <ul className="flex flex-col gap-3">
          {/* Details of boards Tab */}
          {/* <li className=" cursor-pointer ">
            <button
              onClick={() => handleTabClick('DetailsOfBoard')}
              className={`${
                openTab === 'DetailsOfBoard' && 'text-yellow'
              } w-full text-left flex items-center justify-between`}
            >
              <h5 className="font-semibold ">01. Details of Board</h5>
              {openTab === 'DetailsOfBoard' ? <Arrow /> : <Arrow active />}
            </button>
            {openTab === 'DetailsOfBoard' && (
              <ol
                className={`list-disc col-start-1 col-end-4 pb-5 mt-3 flex flex-col gap-2 font-semibold text-green pl-14 `}
              >
                <li>
                  Amandeep Panwar <br /> (Co-founder & CEO)
                </li>
                <li>
                  Rishabh Choudhary <br /> (Co-founder & CTO)
                </li>
                <li>
                  Mukesh Panwar <br /> (Director)
                </li>
              </ol>
            )}
          </li> */}

          <li className="border-b border-opacity-10 border-black 2xl:pb-5 pb-4 mb-2 cursor-pointer">
            <button
              onClick={() => handleTabClick('DetailsOfBoard')}
              className={`${
                openTab === 'DetailsOfBoard' && 'text-yellow'
              } w-full text-left flex items-center justify-between`}
            >
              <h5 className="font-semibold ">01. Details Of Board</h5>
              {openTab === 'DetailsOfBoard' ? <Arrow /> : <Arrow active />}
            </button>

            {openTab === 'DetailsOfBoard' && (
              <ul className="ml-4 mt-3 list-disc pl-3">
                {/* <li
                  className={`mb-2 cursor-pointer ${
                    openPanel === 'MukeshPanwar' &&
                    'underline underline-offset-2 text-green font-semibold'
                  }`}
                  onClick={() => handlePanelClick('MukeshPanwar')}
                >
                  Composition of Committee <br />
                </li> */}
                <li
                  className={`mb-2 cursor-pointer ${
                    openPanel === 'DetailsOfBoard' &&
                    'underline underline-offset-2 text-green font-semibold'
                  }`}
                  onClick={() => handlePanelClick('DetailsOfBoard')}
                >
                  Executive <br />
                </li>
                <li
                  className={`mb-2 cursor-pointer ${
                    openPanel === 'RishabhChoudhary' &&
                    'underline underline-offset-2 text-green font-semibold'
                  }`}
                  onClick={() => handlePanelClick('RishabhChoudhary')}
                >
                  Non Executive <br />
                </li>
              </ul>
            )}
          </li>

          {/* Meetings Tab */}
          <li className="border-b border-opacity-10 border-black 2xl:pb-5 pb-4 mb-2 cursor-pointer">
            <button
              onClick={() => handleTabClick('Meetings')}
              className={`${
                openTab === 'Meetings' && 'text-yellow'
              } w-full text-left flex items-center justify-between`}
            >
              <h5 className="font-semibold ">02. Meetings</h5>
              {openTab === 'Meetings' ? <Arrow /> : <Arrow active />}
            </button>

            {openTab === 'Meetings' && (
              <ul className="ml-4 mt-3 list-disc pl-3">
                <li
                  className={`mb-2 cursor-pointer ${
                    openPanel === 'Meetings' &&
                    'underline underline-offset-2 text-green font-semibold'
                  }`}
                  onClick={() => handlePanelClick('Meetings')}
                >
                  2024
                </li>
                {/* <li
                  className={`mb-2 cursor-pointer ${
                    openPanel === 'Meetings-02' &&
                    'underline underline-offset-2 text-green font-semibold'
                  }`}
                  onClick={() => handlePanelClick('Meetings-02')}
                >
                  Meeting 02
                </li>
                <li
                  className={`mb-2 cursor-pointer ${
                    openPanel === 'Meetings-03' &&
                    'underline underline-offset-2 text-green font-semibold'
                  }`}
                  onClick={() => handlePanelClick('Meetings-03')}
                >
                  Meeting 03
                </li> */}
              </ul>
            )}
          </li>

          {/* Financial Tab */}
          <li className="border-b border-opacity-10 border-black 2xl:pb-5 pb-4 mb-2 cursor-pointer">
            <button
              onClick={() => handleTabClick('Financial')}
              className={`${
                openTab === 'Financial' && 'text-yellow'
              } w-full text-left flex items-center justify-between`}
            >
              <h5 className="font-semibold ">03. Financial Results</h5>
              {openTab === 'Financial' ? <Arrow /> : <Arrow active />}
              
            </button>

            {openTab === 'Financial' && (
              <ul className="ml-4 mt-3 list-disc pl-3">
                <li
                  className={`mb-2 cursor-pointer ${
                    openPanel === 'Financial' &&
                    'underline underline-offset-2 text-green font-semibold'
                  }`}
                  onClick={() => handlePanelClick('Financial')}
                >
                  2024
                </li>
                {/* <li
                  className={`mb-2 cursor-pointer ${
                    openPanel === 'Financial-02' &&
                    'underline underline-offset-2 text-green font-semibold'
                  }`}
                  onClick={() => handlePanelClick('Financial-02')}
                >
                  Half Yearly
                </li>
                <li
                  className={`mb-2 cursor-pointer ${
                    openPanel === 'Financial-03' &&
                    'underline underline-offset-2 text-green font-semibold'
                  }`}
                  onClick={() => handlePanelClick('Financial-03')}
                >
                  Annually
                </li> */}
              </ul>
            )}
          </li>

          {/* Details of group companies */}
          {/* <li className="border-b border-opacity-10 border-black 2xl:pb-5 pb-4 mb-2 cursor-pointer">
            <button
              onClick={() => handleTabClick('GroupCompanies')}
              className={`${
                openTab === 'GroupCompanies' && 'text-yellow'
              } w-full text-left flex items-center justify-between`}
            >
              <h5 className="font-semibold ">04. Group Companies</h5>
              {openTab === 'GroupCompanies' ? <Arrow /> : <Arrow active />}
            </button>

            {openTab === 'GroupCompanies' && (
              <ul className="ml-4 mt-3 list-disc pl-3">
                <li
                  className={`mb-2 cursor-pointer ${
                    openPanel === 'GroupCompanies' &&
                    'underline underline-offset-2 text-green font-semibold'
                  }`}
                  onClick={() => handlePanelClick('GroupCompanies')}
                >
                  Group Companies
                </li>
                <li
                  className={`mb-2 cursor-pointer ${
                    openPanel === 'GroupCompanies-02' &&
                    'underline underline-offset-2 text-green font-semibold'
                  }`}
                  onClick={() => handlePanelClick('GroupCompanies-02')}
                >
                  GroupCompanies 02
                </li>
                <li
                  className={`mb-2 cursor-pointer ${
                    openPanel === 'GroupCompanies-03' &&
                    'underline underline-offset-2 text-green font-semibold'
                  }`}
                  onClick={() => handlePanelClick('GroupCompanies-03')}
                >
                  GroupCompanies 03
                </li>
              </ul>
            )}
          </li> */}
          {/* "TABNAME" "TABNAME-02" tba */}
          <li className="border-b border-opacity-10 border-black 2xl:pb-5 pb-4 mb-2 cursor-pointer">
            <button
              onClick={() => handleTabClick('TABNAME')}
              className={`${
                openTab === 'TABNAME' && 'text-yellow'
              } w-full text-left flex items-center justify-between`}
            >
              <h5 className="font-semibold ">04. Policies</h5>
              {openTab === 'TABNAME' ? <Arrow /> : <Arrow active />}
            </button>

            {/* {openTab === 'TABNAME' && (
              <ul className="ml-4 mt-3 list-disc pl-3">
                <li
                  className={`mb-2 cursor-pointer ${
                    openPanel === 'TABNAME' &&
                    'underline underline-offset-2 text-green font-semibold'
                  }`}
                  onClick={() => handlePanelClick('TABNAME')}
                >
                  Company Policy
                </li>
                <li
                  className={`mb-2 cursor-pointer ${
                    openPanel === 'TABNAME-02' &&
                    'underline underline-offset-2 text-green font-semibold'
                  }`}
                  onClick={() => handlePanelClick('TABNAME-02')}
                >
                  HR Policy
                </li>
                <li
                  className={`mb-2 cursor-pointer ${
                    openPanel === 'TABNAME-03' &&
                    'underline underline-offset-2 text-green font-semibold'
                  }`}
                  onClick={() => handlePanelClick('TABNAME-03')}
                >
                  TABNAME 03
                </li>
              </ul>
            )} */}
          </li>

          {/* ######## ADD MORE TABS HERE ############ */}
        </ul>
      </div>

      {/* All the Panels rendered here*/}

      <div className="flex-1 p-4">
        {/* Details of boards panel*/}
        {openPanel === 'DetailsOfBoard' && <AmanDeep />}
        {openPanel === 'RishabhChoudhary' && <Rishabh />}
        {openPanel === 'MukeshPanwar' && <Mukesh />}

        {/* Meetings panel */}
        {openPanel === 'Meetings' && <Meetings01 />}
        {openPanel === 'Meetings-02' && <Meetings02 />}
        {openPanel === 'Meetings-03' && <Meetings03 />}
        {/* Financial panel */}
        {openPanel === 'Financial' && <Financial01 />}
        {openPanel === 'Financial-02' && <Financial02 />}
        {openPanel === 'Financial-03' && <Financial03 />}

        {/* Group comopanies */}
        {openPanel === 'GroupCompanies' && <GroupCompanies01 />}
        {openPanel === 'GroupCompanies-02' && <GroupCompanies02 />}
        {openPanel === 'GroupCompanies-03' && <GroupCompanies03 />}

        {/* TABNAME (created as dummy so you would know what to change) */}
        {openPanel === 'TABNAME' && <Policy01 />}
        {openPanel === 'TABNAME-02' && 'TABNAME-02'}
        {openPanel === 'TABNAME-03' && 'TABNAME-03'}

        {/* ############### Add more panels as needed  ##########*/}
      </div>
    </div>
  )
}

export default Sidebar

const Arrow = ({ active }: { active?: boolean }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="10"
      viewBox="0 0 17 10"
      className={`mt-2 ${
        !active ? '-rotate-90 fill-yellow' : 'fill-black'
      } transition-all`}
    >
      <path d="M9.88647 8.92749L16.5347 2.27922C16.6587 2.15525 16.7571 2.00807 16.8242 1.84609C16.8912 1.6841 16.9258 1.51049 16.9258 1.33517C16.9258 0.981078 16.7851 0.641491 16.5347 0.391112C16.4108 0.267137 16.2636 0.168794 16.1016 0.1017C15.9396 0.0346049 15.766 7.25056e-05 15.5907 7.2498e-05C15.2366 7.24825e-05 14.897 0.140733 14.6466 0.391112L8.94242 6.10862L3.2382 0.391112C3.11459 0.266485 2.96753 0.167567 2.8055 0.100062C2.64347 0.0325572 2.46968 -0.0021979 2.29415 -0.00219791C2.11862 -0.00219791 1.94482 0.0325572 1.78279 0.100062C1.62076 0.167567 1.4737 0.266485 1.35009 0.391111C1.22547 0.51472 1.12655 0.661781 1.05904 0.823812C0.991536 0.985842 0.956782 1.15964 0.956782 1.33517C0.956782 1.5107 0.991536 1.68449 1.05904 1.84652C1.12655 2.00855 1.22547 2.15561 1.35009 2.27922L7.99836 8.92749C8.12197 9.05212 8.26903 9.15104 8.43106 9.21854C8.59309 9.28605 8.76689 9.3208 8.94242 9.3208C9.11795 9.3208 9.29174 9.28605 9.45377 9.21854C9.6158 9.15104 9.76286 9.05212 9.88647 8.92749Z" />
    </svg>
  )
}
